import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import { styled } from 'linaria/react';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import GridProduct from '../CategoryPage/GridProduct';
import { allContentComponents } from '../ContentComponents/ContentComponents';
import { theme } from '../Theme';
import { BlogBackground, BlogContainer } from './BlogPage';
import BlogQuery from './BlogQuery.gql';
import BlogSidebar from './BlogSidebar';

const BlogArticle = styled('article')`
  background: #fff;
  font-family: ${theme.fonts.primary};
  margin-left: 3rem;
  width: calc(75% - 3rem);
  .youtube-embed {
    display: none;
  }

  h1,
  h2,
  h3,
  h4 {
    text-transform: uppercase;
    letter-spacing: 0;
  }
  h1 {
    font-size: 1.75rem;
    line-height: 1.4;
  }

  h2 {
    padding-top: 10px;
    font-size: 1rem;
    margin-bottom: 0.4rem;
  }
  h3 {
    padding-top: 10px;
  }

  h4 {
    padding-top: 10px;
  }

  p {
    font-size: 1rem !important;
    margin-bottom: 1rem;
    span {
      font-size: 1rem !important;
    }
  }

  ul {
    font-size: 0.85rem;
    list-style-type: disc;
    margin-left: 1rem;
  }

  td {
    font-size: 0.85rem;
    strong {
    }
  }
  strong,
  b {
    font-weight: bold;
  }

  a {
    text-decoration: none;
    transition: all ease 0.3s;
    &:hover {
      color: #000;
      transition: all ease 0.3s;
    }
  }

  ${theme.below.md} {
    margin: 0;
    margin-top: 5rem;
    width: 100%;
  }
`;

const ArticleContent = styled('div')`
  p:first-of-type > img:first-of-type {
    display: none;
  }
  .video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`;

const ContentEditorWrapper = styled('div')`
  background: #fff;
  font-family: ${theme.fonts.primary};
  margin-left: 3rem;
  width: calc(75% - 3rem);

  ${theme.below.lg} {
    width: 100%;
    margin-left: 0;
  }
`;

const ProductGrid = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const Content = styled('div')`
  padding: 10px 30px 30px 30px;
  ${theme.below.md} {
    padding: 20px;
  }
`;

const SingleBlog = ({ location: { pathname } }) => {
  const [post, setPost] = useState(null);
  const { data, loading, error } = useQuery(BlogQuery, {
    variables: {
      pathname
    }
  });

  useEffect(() => {
    if (data && data.route) {
      setPost(data?.route?.object);
    }
  }, [data]);

  if (error) return null;
  if (loading || !post) return null;

  const ceItems =
    data?.route?.object?.data?.items?.length > 0
      ? data?.route?.object?.data?.items
      : null;

  return (
    <BlogBackground>
      <BlogContainer>
        <BlogSidebar path={pathname.split('/')[1]} pathname={pathname} />
        {ceItems ? (
          <ContentEditorWrapper>
            <ContentRenderer
              items={ceItems ?? []}
              components={allContentComponents}
            />
          </ContentEditorWrapper>
        ) : (
          <BlogArticle>
            {post?.images && post?.images?.length > 0 && (
              <img src={post?.images[4]?.url} alt={post?.name} />
            )}
            <Content>
              <h1>{post?.name}</h1>
              <ArticleContent
                dangerouslySetInnerHTML={{ __html: post?.content }}
              />
              <ProductGrid>
                {post?.products?.result?.map(result => (
                  <GridProduct product={result} key={result?.id} />
                ))}
              </ProductGrid>
            </Content>
          </BlogArticle>
        )}
      </BlogContainer>
    </BlogBackground>
  );
};

export default SingleBlog;
